<template>
  <v-container fluid  class="px-0">
          <h1 class="text-center mt-10">NOS MISSIONS</h1>
          <div class="d-flex justify-center mb-10">
            <div style="width: 100px; height: 1px;">
              <hr>
            </div>
          </div>
          <!-- <div v-if="windowWidth >= 620" class="background mt-2">
          </div> -->

          <div v-if="data_ready">
            <v-tabs  v-model="tab" show-arrows hide-slider >
              <div class="d-flex justify-center" style="width: 100%;">
                <v-tab v-for="(item, index) in page_data.mission" :key="index" class="d-flex title mr-5">
                  {{item.title}}
                </v-tab>
              </div>
    
              <v-tab-item v-for="(item, index) in page_data.mission" :key="index" >

                <!-- <v-card flat color="transparent" height="620">
                  <v-card flat color="transparent pt-16" height="590">

                  </v-card>

                </v-card> -->
                <div class="d-flex align-center mt-5">
                  <v-card flat class="d-flex justify-center test1" style="width: 100%;"  :height="text_height">
                    <div v-if="$vuetify.breakpoint.lgAndUp" style="min-width: 10%"></div>
                    <v-card flat color="transparent" class="d-flex align-center px-10 mt-14 mb-8" :min-width="text_width" max-width="630"  style="overflow: hidden">
                      <v-card class="" :class="{ '' : $vuetify.breakpoint.mdAndDown}" flat color="transparent" max-height="100%" style=" z-index: 2">
                        <EditableText @onEdit="event => editContent(event, item.id, 'content')" :text="item.content"/>
                      </v-card>
                    </v-card>

                    <v-card flat color="transparent"  v-if="$vuetify.breakpoint.mdAndUp" class="d-flex justify-end align-start flex-grow-1 mt-9 mb-13 " style="min-width: 10%; max-width: 70%; margin-left: -5%" >
                      <v-img  :src="require(`@/assets/${item.img}`)" height="100%">
                        <div class="fill-height bottom-gradient"></div>
                      </v-img>
                    </v-card>
                  </v-card>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>

  </v-container>

</template>

<script>
import Edit from "@/components/Edit";
import EditableText from "@/components/EditableText";
export default {
  extends: Edit,
  name: 'Missions',
  components: {
    EditableText
  },
  computed: {
    text_width() {
      if (this.windowWidth < 720)
        return "0"
      else
        return "620"
    },
    text_height() {
      if (this.windowWidth < 720)
        return "100%"
      else
        return "600"
    },
  },
  data: () => (
      {
        missions: null,
        tab: null,
        data_ready: false,
        page_name: "missions",
        env : process.env.VUE_APP_URL
      }
  ),
  // mounted() {
  //   this.get_missions_data()
  // },
  // methods: {
  //   get_missions_data() {
  //     this.$http.get("http://localhost:3000/pageitems/missions")
  //         .then(response => {
  //           console.log(response.data)
  //           this.missions = response.data['mission']
  //           this.data_ready = true
  //         })
  //         .catch(error => console.log(error))
  //   }
  // }
}
</script>

<style scoped>
  /deep/ .v-tabs-items {
    background-color: unset;
  }
    /* /deep/ .v-tabs > .v-tabs-bar{
    background-color: unset;
  } */
  .title {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.5rem;
    color: black;
  }
    .v-overlay > :nth-child(2) {
    width: 60% !important;
    height: 600px !important;
    /* align-items: end !important; */
  }
  /* #myelement {
    position: relative;
    overflow: hidden;
  }
  #myelement::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
    z-index: 10;
    background: #f7f7f7;
    transform: rotate(30deg);
  } */

  .img-wrap {
    display: block;
    content: '';
    position: absolute;
    width: 100%;

    height: 620px;
    background: #f7f7f7;
  }

  .test1 {
    background: #f7f7f7;
    height: 600;
    clip-path: polygon(0% 10%, 100% 0%, 100% 90%, 0% 100%);
    width: 100%;
  }

  .background {
    background: #f0f0f0;
    transform:rotate(-2deg) ;
    position: absolute;
    top: 25vh;
    height: 490px;
    left: -5%;
    width: 105%;
  }
  /* .background:after {
    transform: rotate(45deg) translateY(-45px);
    background: #f7f7f7;
    width: 100%;
  } */
  .v-tab--active{
    color: orange
  }
  .bottom-gradient {
        background: linear-gradient(to bottom, #f7f7f7, rgba(247, 247, 247,0.93) 10%, rgba(247, 247, 247,0) 40%, rgba(247, 247, 247,0.5) 80%, #f7f7f7),
                      linear-gradient(to right, #f7f7f7,rgba(247, 247, 247,0.90) 10%, rgba(247, 247, 247,0) 60%);
  }
</style>