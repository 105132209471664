<template>
    <v-container fill-height="fill-height">
        <v-layout align-center="align-center" justify-center="justify-center">
          <v-flex class="login-form text-xs-center"> 
            <div class="display-1 mb-3">
              <v-icon class="mr-2" large="large"></v-icon> BackOffice
            </div>
            <v-card light="light">
              <v-card-text>
                <div class="subheading">
                  <template v-if="isLoggingIn">Connectez vous au BackOffice</template>
                </div>
                <v-form @submit.prevent="handleSubmit">
                  <v-text-field v-if="!isLoggingIn" v-model="username" light="light" prepend-icon="person" label="Name"></v-text-field>
                  <v-text-field v-model="username" light="light" prepend-icon="mdi-account" label="Login" type="email"></v-text-field>
                  <v-text-field v-model="password" light="light" prepend-icon="mdi-lock" label="Password" type="password"></v-text-field>
                  <vue-recaptcha ref="recaptcha" @verify="onVerify" sitekey="6LfVWagaAAAAAPhTez0Z5PTJO0nM-7WVVb1fVASR" style="max-width:100%">
                  </vue-recaptcha><br>
                  <v-btn v-if="isLoggingIn" block="block" type="submit">Sign in</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { userService } from '@/helpers/user-service.js';

export default {
    name: 'LoginPage',
    components: { VueRecaptcha },
    data () {
        return {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            isLoggingIn : true,
            returnUrl: '',
            error: '',
            robot: false
        }
    },
    mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
      document.head.appendChild(recaptchaScript)
    },
    created () {
        // reset login status
        this.$emit("dataready", {})
        userService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.$route.query.returnUrl || '/';
    },
    methods: {
        handleSubmit () {
          if (this.robot) {
            this.submitted = true;
            const { username, password } = this;

            // stop here if form is invalid
            if (!(username && password)) {
                return;
            }

            this.loading = true;
            userService.login(username, password)
                .then(response => {
                    console.log(response)
                    console.log("emiting")
                    this.$bus.$emit('logged', 'User logged')
                    this.$router.push(this.returnUrl)
                })
                .catch(error => console.log("ERROR=",error))
          }
        },
        onVerify: function (response) {
        if (response) this.robot = true;
      }
    }
};
</script>
<style scoped>
p {
  line-height: 4 !important;
}
.login-form {
  max-width: 500px
}
</style>