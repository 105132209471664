<template>
  <v-container fluid class="back" style="min-height: 100vh">
    <h1 class="text-center mt-10" style="font-size: 2.4rem">Contact</h1>
    <div class="d-flex justify-center mb-16">
      <div style="width: 100px; height: 1px;">
        <hr>
      </div>
    </div>

    <ContactForm fullPage/>

  </v-container>
</template>

<script>
  import ContactForm from '../components/ContactForm.vue'

export default {
  name: 'Contact',
  components: {
    ContactForm
  },
  computed: {
  },
}
</script>

<style scoped>

.back {
  background: url("../assets/slide-1.jpg");
  color: white;
  background-size: cover;
}


</style>