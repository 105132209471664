<template>
  <v-footer id="myfooter" class="back">
    <ContactForm/>
  </v-footer>
</template>

<script>
  import ContactForm from '../components/ContactForm.vue'
  export default {
    name: 'Footer',
    components: { ContactForm },
  }
</script>


<style scoped>

.back {
  background: url("../assets/slide-1.jpg");
  color: white;
  background-size: cover;
}


</style>