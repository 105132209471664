<template>
  <v-container v-if="news !== null" >
    <div class="d-flex justify-center">
      <h1 class="text-center mt-10">{{news.title}}</h1>
    </div>
    <div class="d-flex justify-center">
      <div style="width: 100px; height: 50px;">
        <hr>
      </div>
    </div>
    <v-card flat class="mb-10">
          <div class="d-flex justify-center">
            <v-img max-width="600px" v-bind:src="news.img" />
          </div>
          <div class="d-flex justify-center mt-15">
            <v-card flat width="50%" class="mr-15">
              <div v-html="news.content_first">
              </div>
            </v-card>
            <v-card flat width="50%">
              <div v-html="news.content_second">
              </div>
            </v-card>
          </div>
    </v-card>

  </v-container>
</template>


<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'Actualite',
  props: {
    newsId: String,
  },

  data: () => (
      {
        news : null
      }
  ),
  mounted() {
    this.get_news_content()
  },
  methods: {
    get_news_content() {
      this.$http.get(process.env.VUE_APP_URL+"/api/actualites/" + this.newsId)
          .then(response => {
            this.news = response.data
            var half = this.news.content.length / 2
            var position = this.news.content.indexOf(`<h`, half)
            if (position === -1)
              position = this.news.content.indexOf(`<p>`, half)
            this.news["content_first"] = this.news.content.slice(0, position)
            this.news["content_second"] = this.news.content.slice(position)
          })
          .catch(error => console.log(error))

    }
  }
}
</script>

<style>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}


</style>