import Vue from 'vue';
import Vuetify from 'vuetify/lib'

const vuetifyOpts = {
    rtl: false,
    theme: {
        themes: {
            dark: {
                sillageBlue: '#0f286c',
                sillageOrange: '#ed822a',
            },
            light: {
                sillageBlue: '#0f286c',
                sillageOrange: '#ed822a',
            }
        }
    },
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
}

Vue.use(Vuetify)


export default new Vuetify(vuetifyOpts);
