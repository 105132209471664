<template>
    <v-app-bar
        app
        :color="getColor()"
        dark
        :key="render"
        >
      <div class="d-flex align-center">
        <router-link 
          to="/home">
        <v-img
          alt="Vuetify Name"
          class="shrink mt-1"
          contain
          width="100"
          src="@/assets/logo-white.png"
        />
        </router-link>
      </div>
          <v-tabs class="hidden-md-and-down ml-5" :class="{ 'no-underline': isHome }" style="min-width: 1px;" >
            <v-tabs-slider color="white"></v-tabs-slider>
            <v-tab v-on:click="goToCabinet()" ref="tabs"><h3>Le Cabinet</h3></v-tab>
            <v-tab to="/missions"><h3>Nos Missions</h3></v-tab>
            <v-tab to="/vousEtes"><h3>Vous Êtes ?</h3></v-tab>
            <v-tab to="/service"><h3>Notre Web Service</h3></v-tab>
            <v-tab to="/actualites"><h3>Actualités</h3></v-tab>
            <v-tab to="/contact"><h3>Contact</h3></v-tab>
            <v-tab to="/recrutement"><h3>Candidature</h3></v-tab>
          </v-tabs>
        <v-spacer></v-spacer> 
        <div class="d-flex align-center nav-item">
          <v-btn v-if="isLoggedIn()" to="?save" class="ma-2 hidden-md-and-down" color="secondary" dark>
          Sauvegarder
          </v-btn>
           <!-- <v-btn v-if="!isLoggedIn()"  to="/recrutement" class="ma-2 hidden-md-and-down" color="secondary" dark>
            Candidature
          <v-icon dark right>mdi-briefcase</v-icon>
          </v-btn> -->
        </div>
        <div class="d-flex align-center nav-item">
          <v-btn v-if="isLoggedIn()" @click="disconnect" class="ma-2 hidden-md-and-down" color="sillageOrange" dark>
            Déconnexion
          </v-btn>

          <v-btn v-if="!isLoggedIn()" class="ma-2 hidden-md-and-down" color="sillageOrange" dark href="https://espaceclient.sillage-experts.fr/isuiteexpert/">
            Espace Client
            <v-icon dark right>
              mdi-account-circle
            </v-icon>
          </v-btn>
        </div>

        <v-menu
          transition="slide-y-transition"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center" style="height: 100%">
              <v-btn
                class="purple hidden-lg-and-up"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Menu
              </v-btn>
            </div>
          </template>
        <v-list>
          <v-list-item v-on:click="goToCabinet()">
            <v-list-item-title>Le Cabinet</v-list-item-title>
          </v-list-item>
          <v-list-item to="/missions">
            <v-list-item-title>Nos Missions</v-list-item-title>
          </v-list-item>
          <v-list-item to="/vousEtes">
            <v-list-item-title>Vous Êtes ?</v-list-item-title>
          </v-list-item>
          <v-list-item to="/service">
            <v-list-item-title>Notre Web Service</v-list-item-title>
          </v-list-item>
          <v-list-item to="/actualites">
            <v-list-item-title>Actualités</v-list-item-title>
          </v-list-item>
          <v-list-item to="/contact">
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item>
          <v-list-item to="/recrutement">
            <v-list-item-title>Candidature</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isLoggedIn()">
            <v-list-item-title>
              <v-btn class="ma-2" color="sillageOrange" dark href="https://espaceclient.sillage-experts.fr/isuiteexpert/">
                Espace Client
                <v-icon dark right>mdi-briefcase</v-icon>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isLoggedIn()">
            <v-list-item-title>
              <v-btn to="?save" class="ma-2" color="secondary" dark >
                Sauvegarder
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isLoggedIn()">
            <v-list-item-title>
              <v-btn class="ma-2" color="sillageOrange" dark @click="disconnect">
                Deconnection
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        
      </v-menu>
    </v-app-bar>
</template>

<script>
import { userService } from '@/helpers/user-service.js';

  export default {
    name: 'Navbar',
    data: () => (
        {
          bg: "transparent",
          isHome: false, 
          scrolled: false,
          render: 0
        }
    ),
    created() {
      this.$bus.$on('logged', () => {
        this.render += 1
      })
    },
    mounted() {
      this.updateDatas();
      window.onscroll = () => {
        this.updateDatas();
      };
    },
    watch: {
      $route () {
        this.updateDatas()
      }
    },
    methods: {
      isLoggedIn() {
        return localStorage.getItem('user') != null
      },
      disconnect() {
        userService.logout()
        this.$router.go()
      },
      save() {
        window.history.replaceState(null, null, "?arg=123")
      },
      getColor() {
        if (this.$route.name !== 'Home')
          return "sillageBlue"
        else
          return this.bg
      },
      updateDatas() {
        this.isHome = this.$route.name === 'Home' && document.documentElement.scrollTop < 500
        if (
            document.body.scrollTop > 1 ||
            document.documentElement.scrollTop > 1
        ) {
          this.bg = 'sillageBlue';
          this.scrolled = true;
        } else {
          this.bg = 'transparent';
          this.scrolled = false;
        }
      },
      goToCabinet() {
        var isHome = this.$route.name === 'Home'
        if (this.$route.hash != "#cabinet")
          this.$router.push({ name: 'Home', hash: '#cabinet' })
        if (isHome)
        {
            var options = {
            lazy: false,
            offset: -80,
            force: true,
            cancelable: true,
            x: false,
            y: true
          }
          this.$scrollTo('#cabinet', 500, options)
        }
        console.log(this.$refs.tabs)
        this.$refs.tabs.onResize()
        
      }
    }


  }
</script>

<style scoped>

.nav-item {
  height: 100%;
}

/* h3 {
  font-size: 1.05rem;
}

.v-btn.v-size--default {
  font-size: 0.95rem;
} */


/deep/ .v-tabs-slider {
  opacity: 1;
  transition: opacity 0.5s linear;
}

/deep/ .no-underline .v-tabs-slider {
  opacity: 0;
  transition: opacity 0.5s linear;
}


.test:hover {
  background-color:#F5F5F5
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* v-tab {
  font-size: 0.875rem;
  } */

</style>