import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Cabinet from '../views/Cabinet.vue'
import Missions from '../views/Missions.vue'
import VousEtes from '../views/VousEtes.vue'
import VousEtesDesc from '../views/VousEtesDesc.vue'
import Actualite from '../views/Actualite.vue'
import Actualites from '../views/Actualites.vue'
import Service from '../views/Service.vue'
import Contact from '../views/Contact.vue'
import LoginPage from '../views/LoginPage.vue'
import Recrutement from '../views/Recrutement.vue'
import Mentions from '../views/Mentions.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/home",
    meta: {title:"Sillage Experts"}
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {title:"Sillage Experts"}
  },
  {
    path: '/cabinet',
    name: 'Cabinet',
    component: Cabinet,
    meta: {title:"Sillage Experts - Le Cabinet"}
  },
  {
    path: '/missions',
    name: 'Missions',
    component: Missions,
    meta: {title:"Sillage Experts - Missions"}
  },
  {
    path: '/recrutement',
    name: 'Recrutement',
    component: Recrutement,
    meta: {title:"Sillage Experts - Recrutement"}
  },
  {
    path: '/vousEtes',
    name: 'VousEtes',
    component: VousEtes,
    meta: {title:"Sillage Experts - VousEtes"}
  },
  {
    path: '/vousEtesDesc/:id',
    name: 'VousEtesDesc',
    component: VousEtesDesc,
    meta: {title:"Sillage Experts - VousEtes"},
    props(route) {
      return {
        pageid: route.params.id
      };
    },
  },
  {
    path: '/service',
    name: 'Service',
    component: Service,
    meta: {title:"Sillage Experts - Service"}
  },
  {
    path: '/actualites',
    name: 'Actualites',
    component: Actualites,
    meta: {title:"Sillage Experts - Actualite"}
  },
  {
    path: '/actualite/:id',
    name: 'Actualite',
    component: Actualite,
    meta: {title:"Sillage Experts - Actualite"},
    props(route) {
      return {
        newsId: route.params.id
      };
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {title:"Sillage Experts - Contact"},
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {title:"Sillage Experts - Login"}
  },
  {
    path: '/mentions',
    name: 'Mentions',
    component: Mentions,
    meta: {title:"Sillage Experts - Mentions"}
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from) {
    if (to.hash) {
      if (to.name != from.name)
        {
          return { selector: to.hash, offset : { x: 0, y: 100 } };
        }
    }
    return { x: 0, y: 0 }
  },  
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
