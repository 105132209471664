<script>
// @ is an alias to /src
import { userService } from '@/helpers/user-service.js';

export default {
  name: 'Edit',
  components: {
    // HelloWorld
  },
  computed: {
  },
  mounted() {
    this.logged_in = localStorage.getItem('user') != null
    this.get_data()
  },
  data: () => (
      {
        logged_in: false,
        page_data: null,
        edited_datas: {},
        data_ready: false,
      }
  ),
  watch: {
    $route (to) {
      var route = to.fullPath
      if (route.length > 5)
      {
        var lastFive = route.substr(route.length - 5)
        if (lastFive === "?save")
          this.save_data()
      }
    }
  },
  methods: {
    is_logged_in() {
      return this.logged_in
    },
    editContent(content, id, field) {
      console.log("updating ", content, "  id = ", id,  "  field = ", field)
      if (!(id in this.edited_datas))
      {
        this.edited_datas[id] = {}
      }
      this.edited_datas[id][field] = content
    },
    save_data() {
      if (Object.entries(this.edited_datas).length === 0)
        {
          this.$router.push(this.$route.path)
          this.$router.go()
        }
      else
      {
        userService.editData(this.edited_datas)
            .catch(error => console.log("ERROR=",error))
            .finally(() => {
              this.edited_datas = {}
              this.$router.push(this.$route.path)
              this.$router.go()
            })
      }

    },
    get_data() {
      this.edited_datas = {}
      this.$http.get(process.env.VUE_APP_URL + "/api/pageitems/" + this.page_name)
          .then(response => {
            this.page_data = response.data
            this.data_ready = true
          })
          .catch(error => console.log(error))
    },
  }

}
</script>
