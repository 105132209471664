<template>
    <v-container fluid class="background mt-8">
        <v-container class="d-flex justify-start">
            <v-card flat color="transparent" width="800">
                <h1 class="ml-15" style="line-height: 1;color: #ed822a">
                    <span style="font-weight: lighter; color: black">Espace</span><br/>
                    Recrutement
                </h1><br/>
                <h3 class="ml-15">Vous êtes intéréssé(e) pour rejoindre nos équipes, envoyez-nous vos motivations accompagnées d'un cv !</h3>
                <v-col class="pa-15">
                    <form >
                        <div class="d-flex justify-center" :class="{'flex-wrap': $vuetify.breakpoint.xsOnly}">
                            <v-text-field :class="{'mr-10': $vuetify.breakpoint.smAndUp}" outlined v-model="form.lastname" :error-messages="nameErrors" label="Nom" required></v-text-field>
                            <v-text-field  outlined v-model="form.firstname" :error-messages="emailErrors" label="Prénom" required ></v-text-field>
                        </div>
                        <div class="d-flex justify-center" :class="{'flex-wrap': $vuetify.breakpoint.xsOnly}">
                            <v-text-field :class="{'mr-10': $vuetify.breakpoint.smAndUp}" outlined v-model="form.email" :error-messages="emailErrors" label="E-mail" required></v-text-field >
                            <v-text-field v-model="form.phone" outlined :error-messages="emailErrors" label="Téléphone" required style="min-width: 110px"></v-text-field>
                        </div>
                        <v-textarea v-model="form.job" outlined rows="4" label="Emploi et responsabilités recherchées" auto-grow></v-textarea>
                        <div class="d-flex justify-center" :class="{'flex-wrap': $vuetify.breakpoint.smAndDown}">
                            <v-file-input v-model="formFile.cv" :class="{'mr-10': $vuetify.breakpoint.mdAndUp}" accept=".doc,.docx,.pdf" outlined truncate-length="50" label="Curriculum Vitae (doc, docx ou pdf)" style="min-width: 250px"></v-file-input>
                            <v-file-input v-model="formFile.motiv" accept=".doc,.docx,.pdf" outlined truncate-length="50" label="Lettre de Motivation (doc, docx ou pdf)" style="min-width: 250px"></v-file-input>
                        </div>
                        <v-text-field v-model="form.object" outlined :error-messages="emailErrors" label="Objet" required></v-text-field>
                        <v-textarea v-model="form.message" outlined rows="4" label="Votre message" auto-grow></v-textarea>
                        <vue-recaptcha ref="recaptcha" @verify="onVerify" sitekey="6LfVWagaAAAAAPhTez0Z5PTJO0nM-7WVVb1fVASR">
                        </vue-recaptcha><br>
                        <v-btn class="mr-4" @click="submit">
                        Envoyer
                        </v-btn>
                    </form>
                    <v-alert :value="mailOk" type="success">Votre message a bien été envoyé</v-alert>
                    <v-alert :value="mailFail" type="danger">Il y a eu un problème à la transmission de votre messaye, essayez plus tard.</v-alert>
                </v-col>
            </v-card>

        </v-container>
    </v-container>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
export default {
  name: 'Recrutement',
  components: {
    VueRecaptcha
  },
  data: () => (
        { 
        mailOk : false,
        mailFail : false,
        nameErrors: "",
        emailErrors: "",
        form :{
            name: "",
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            object: "",
            job: "",
            message: "",
            robot : false,
        },
        formFile: {
            cv: null,
            motiv: null
        }
      }
      
    ),
    mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
      document.head.appendChild(recaptchaScript)
    },
    methods: {
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        submit() {
            if (this.form.robot){
                if (this.formFile) {
                    let formData = new FormData();
                    // files
                    for (let file in this.formFile) {
                        console.log(file)
                        formData.append(file, this.formFile[file]);
                    }
                    // additional data
                    for (let data in this.form){
                        formData.append(data, this.form[data]);
                    }
                    this.$http
                        .post(process.env.VUE_APP_URL+"/api/recrutement", formData)
                        .then(response => {
                            if (response.status == 200){
                                this.mailOk = true
                                this.reset()
                                setTimeout(()=>{
                                    this.mailOk = false;
                                },2000);
                            }
                            else
                                this.mailFail = true
                                setTimeout(()=>{
                                    this.mailFail = false;
                                },2000);
                        })
                        .catch(error => {
                            console.log({ error });
                        });
                } else {
                    console.log("there are no files.");
                }
            }
        },
        onVerify: function (response) {
            if (response) this.form.robot = true;
        },
        reset(){
            this.form.name = ""
            this.form.firstname = ""
            this.form.lastname = ""
            this.form.email = ""
            this.form.phone = ""
            this.form.object = ""
            this.form.job = ""
            this.form.message = ""
            this.form.robot = false
            this.formFile.cv = null
            this.formFile.motiv = null
        }
    }
}
</script>
<style scoped>
 .background{
    background-image: url( 'https://www.sillage-experts.fr/assets/images/page/le-cabinet-2.png');
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
  }
  /deep/ .v-input__slot {
      background-color: rgba(255, 255, 255, 0.8) !important
  }
</style>
 