import EventBus from './EventBus'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import Axios from "axios"
import VueExpandableImage from 'vue-expandable-image'
import VueSocialSharing from 'vue-social-sharing'
// import { VueReCaptcha } from 'vue-recaptcha-v3'
import "./styles/variables.scss"
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
// don't forget to import CSS styles
import VueWindowSize from 'vue-window-size';

Vue.use(VueWindowSize);


var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)



// Vue.use(VueReCaptcha, { siteKey: '6LfFq2QaAAAAAAqYjyKhMvoNndX_FWgTchY2Ku' })
Vue.use(VueExpandableImage)
Vue.use(VueSocialSharing);
Vue.config.productionTip = false
Vue.prototype.$bus = EventBus


// use axios
Vue.prototype.$http = Axios;

Vue.use(vuetify);

new Vue({
  router,
  vuetify,
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  render: h => h(App)
}).$mount('#app')
