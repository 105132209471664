<template>
      <v-container class="mt-6">
        <v-card dark flat color="transparent">
            <v-alert :value="mailOk" type="success">Votre message a bien été envoyé</v-alert>
            <v-alert :value="mailFail" type="error">Il y a eu un problème à la transmission de votre messaye, essayez plus tard.</v-alert>
            <v-row class="py-3">
              <v-col cols="12" sm="4" style="min-width: 250px" class="pl-5" :class="$vuetify.breakpoint.xsOnly ? 'd-flex justify-space-between flex-wrap' : ''">
                  <div class="mr-2 mb-10">
                    <v-img src="@/assets/logo-white.png" alt="" width="175" max-width="175"></v-img>
                  </div>
                <div class="">
                    <p class="adresse mr-10">
                      13/15 rue de l'Ambroisie<br>
                      75012 Paris<br>
                      <span class="telephone">01 53 46 66 46</span>
                    </p>

                      <p class="adresse mr-2">
                        11 rue du Général Leclerc<br>
                        94520 Mandres les Roses<br>
                        <span class="telephone">01 45 98 92 00</span>
                      </p>

                    <router-link 
                      to="/mentions" style="color: white">
                      Mentions Légales
                    </router-link>
                    <div class="d-flex justify-start mt-6">
                        <a href="https://fr-fr.facebook.com/sillageexperts/">
                            <v-icon x-large>mdi-facebook</v-icon>
                        </a>
                        <a class="ml-10" href="https://www.linkedin.com/company/sillagexperts/">
                            <v-icon x-large>mdi-linkedin</v-icon>
                        </a>
                         <ShareNetwork class="ml-10" network="twitter"
                                url="https://www.sillage-experts.fr/"
                                title="Je recommande le cabinet d'experts comptable Sillage-Experts"
                                description="Le cabinet Sillage-Experts est un cabinet de confiance qui priviligie la qualité du service et est toujours à l'écoute de ses clients."
                                hashtags="comptable,sillage-experts"
                            >
                                <v-icon x-large>mdi-twitter</v-icon>
                        </ShareNetwork>
                    </div>

                </div>
              </v-col>
              <v-col cols="12" sm="6" md="8" class="d-flex align-content-center flex-wrap">
                <h1 v-if="!fullPage" style="font-size: 2.5rem; width: 100%">CONTACT</h1>
                <form style="max-width: 100%">
                    <div class="d-flex justify-start flex-wrap">
                        <v-text-field class="ml-5 mr-5" v-model="form.lastname" :error-messages="nameErrors" label="Nom" required style="min-width: 50px"></v-text-field>
                        <v-text-field class="ml-5 mr-5" v-model="form.firstname" :error-messages="emailErrors" label="Prénom" required  ></v-text-field>
                        <v-text-field class="ml-5 mr-5" v-model="form.email" :error-messages="emailErrors" label="E-mail" required></v-text-field>
                        <v-text-field class="ml-5 mr-5" v-model="form.phone" :error-messages="emailErrors" label="Téléphone" required></v-text-field>
                    </div>
                    <div class="ml-5 mr-5">
                        <v-text-field v-model="form.object" :error-messages="emailErrors" label="Objet" required></v-text-field>
                        <v-textarea :class="fullPage ? 'mt-16': ''" :outlined="fullPage" v-model="form.message" :rows="fullPage ? '8' : '4'" label="Votre message" auto-grow></v-textarea>
                        <vue-recaptcha ref="recaptcha" @verify="onVerify" sitekey="6LfVWagaAAAAAPhTez0Z5PTJO0nM-7WVVb1fVASR" style="max-width:100%">
                        </vue-recaptcha><br>
                        <v-btn class="mr-4" @click="submit">
                        Envoyer
                        </v-btn>
                    </div>
                </form>
              </v-col>
            </v-row>
        </v-card>
        <br><br>
      </v-container>

</template>

<script>
  import VueRecaptcha from 'vue-recaptcha';
  export default {
    name: 'ContactForm',
    props: {
        fullPage: Boolean,
    },
    components: { VueRecaptcha },
    computed: {
      ImgHeight() {
        if (this.$route.name === 'Contact')
          return "100vh"
        if (this.$vuetify.breakpoint.smAndUp)
          return 600
        else
          return 830
      },
      LogoWidth() {
        if (this.$vuetify.breakpoint.smAndUp)
          return 175
        else
          return 100
      }
    },
    data: () => ({
      mailOk : false,
      mailFail : false,
      nameErrors: "",
      emailErrors: "",
      form :{
        name: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        object: "",
        message: "",
        robot: false
      }
    }),
    mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
      document.head.appendChild(recaptchaScript)
    },
    methods: {
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      submit() {
        if (this.form.robot) {
          this.$http.post(process.env.VUE_APP_URL+"/api/contact", this.form)
            .then(response => {
              if (response.status == 200){
                this.mailOk = true
                this.reset()
                this.sleep(3000)
                this.mailOk = false
              }
              else
                this.mailFail = true
                this.sleep(3000)
                this.mailFail = false
            })
            .catch(error => console.log(error))
          }
      },
      onVerify: function (response) {
        if (response) this.form.robot = true;
      },
      reset(){
        this.form.name = ""
        this.form.firstname = ""
        this.form.lastname = ""
        this.form.email = ""
        this.form.phone = ""
        this.form.object = ""
        this.form.message = ""
        this.form.robot = false
      }
    }
  }
</script>


<style scoped>
   /* .v-application a{
    color: transparent;
  } */

  /deep/ .theme--dark.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
      color: rgba(255, 255, 255, 0.7);
  }
</style>