<template>
<v-container>
  <h1 class="text-center mt-10">NOTRE WEBSERVICE</h1>
  <div class="d-flex justify-center mb-16">
    <div style="width: 100px; height: 1px;">
      <hr>
    </div>
  </div>
    <v-row class="mb-15">
      <v-col v-for="(item, index) in page_data.service_item" :key="index" class="d-flex justify-center">
          <v-card class="text-left" width="350" :elevation="6" >
            <!-- <div class="d-flex justify-center">
              <v-card  width="500" color="sillageOrange" >

              </v-card>
            </div> -->

            <v-img :src="require(`@/assets/${item.img}`)" :lazy-src="require(`@/assets/lazy/${item.img}`)"></v-img>
            <!-- <v-card-title class="d-flex justify-center" style="font-size: 1.45rem">
              {{card.title}}
            </v-card-title> -->
            <v-card-text>
              <EditableText @onEdit="event => editContent(event, item.id, 'presentation')" :text="item.presentation"/>
            </v-card-text>
            <div v-if="item.additional_img">
              <expandable-image style="backdrop-filter: blur(5px);" close-on-background-click class="ma-10" :src="require(`@/assets/${item.additional_img}`)"/>
            </div>
          </v-card>
      </v-col>
    </v-row>
</v-container>
</template>

<script>
import Edit from "@/components/Edit";
import EditableText from "@/components/EditableText";

export default {
  extends: Edit,
  name: 'Service',
  components: {
    EditableText
  },
  data: () => (
      {
        tab: null,
        page_name: "service",
        env : process.env.VUE_APP_URL
      }
  )
}
</script>

<style scoped>
  .title {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.5rem;
    color: black;
  }
    .v-overlay > :nth-child(2) {
    width: 100% !important;
    height: 100% !important;
    background: linear-gradient(90deg, rgba(250,250,250,1) 70%, rgba(255,255,255,0) 100%);
    align-items: end !important;
  }
  .expandable-image.expanded{
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(2px);
  }
  .background{
    background: linear-gradient(90deg, rgba(250,250,250,1) 70%, rgba(255,255,255,0) 100%);
  }
  .v-tab--active{
    color: orange
  }
  .rounded-card{
    border-radius:50px;
  }
/* 
/deep/ .v-image__image--cover {
  background-size: 130%
} */

</style>