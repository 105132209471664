<template>
  <v-container v-if="data_ready" fluid class="pa-0">
    <div class="d-flex justify-center">
      <h2 class="text-center mt-10">
        <EditableText @onEdit="event => editContent(event, page_data.id, 'title')" :text="page_data.title"/>
      </h2>
    </div>
    <div class="d-flex justify-center">
      <div style="width: 100px; height: 50px;">
        <hr>
      </div>
    </div>
    <v-card flat class="pa-0" color='white'>
      <v-container class="mb-5">
        <div class="d-flex justify-center mt-5">
          <EditableText @onEdit="event => editContent(event, page_data.id, 'content')" :text="page_data.content" class="ma-15"/>
          <!-- <div v-html="page_data.content" class="ma-15">
          </div> -->
          <v-img v-if="$vuetify.breakpoint.mdAndUp" :src="require(`@/assets/${page_data.img}`)" :lazy-src="require(`@/assets/lazy/${page_data.img}`)" width="45%"/>
        </div>
        <br>
      </v-container>
    </v-card>
    <v-card v-if="page_data.additional_img != null" flat class="pa-0" color='grey lighten-4'>
      <v-container class="mb-5">
        <div class="d-flex justify-center mt-5">
          <v-img v-if="$vuetify.breakpoint.mdAndUp" position="60% 50%" :src="require(`@/assets/${page_data.additional_img}`)" :lazy-src="require(`@/assets/lazy/${page_data.additional_img}`)" width="50%"/>
          <div class="ma-15">
            <EditableText @onEdit="event => editContent(event, page_data.id, 'content')" :text="page_data.additional_text"/>
          </div>
        </div>
        <br>
      </v-container>
    </v-card>

  </v-container>
</template>


<script>
import Edit from "@/components/Edit";

import EditableText from "@/components/EditableText";

export default {
  extends: Edit,
  name: 'VousEtesDesc',
  props: {
    pageid: String
  },
  components:{
    EditableText
  },
  mounted() {
    this.get_data()
  },
  data: () => (
      {
        page_data: {},
        data_ready : false
      }
  ),
  methods: {
    get_data() {
      this.$http.get(process.env.VUE_APP_URL + "/api/pageitems/id/" + this.pageid)
          .then(response => {
            this.page_data = response.data
            this.data_ready = true
            this.$emit("dataready", {})
          })
          .catch(error => console.log(error))
    }
  }
}
</script>

<style>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}


</style>