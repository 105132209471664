<template>
<div style="max-width: 100%">
  <div v-if="isLoggedIn"> 
    <quill-editor
    v-model="content"
    @focus="onFocus" 
    @change="onUpdate"
    v-click-outside="onUnFocus"
    v-bind:class="{ invisibleToolbar: !isVisible }" 
    :options="editorOption"
  />
  </div>
  <div v-else v-html="content" class="content">
  </div>
<div >
</div>

</div>
</template>

<script>

import Quill from 'quill'
window.Quill = require('quill');
import { quillEditor } from 'vue-quill-editor'

import '@/styles/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
import ImageResize from 'quill-image-resize-module';
let Inline = Quill.import('blots/inline');

class SpanBlock extends Inline{    
 // eslint-disable-next-line no-unused-vars 
    static create(value){
        let node = super.create();
        node.setAttribute('class','spanblock');
        return node;    
    } 
}

SpanBlock.blotName = 'spanblock';
SpanBlock.tagName = 'div';
Quill.register(SpanBlock);

Quill.register('modules/imageResize',ImageResize);



export default {
  props: {
    text: String
  },
  components: {
    quillEditor
  },
  computed: {
    isLoggedIn() {
      return localStorage.getItem('user') != null
    }
  },
  data() {
    return {
    machin: false,
    content: null,
    editorFocused: null,
    editorOption: {
      theme: 'snow',
      modules: {

        toolbar: 
        {
          container: [
            ["bold", "italic", "underline"],
            [{ 'color': ["#000000", "#0f286c", "#ed822a"]}],
            ['spanblock']
          ],
        },

        imageResize:{
          displayStyles:{
          backgroundColor:'black',
          border:'none',
          color:'white'
        },
        modules:['Resize','DisplaySize','Toolbar']
        }
      }
    },
    ts: [
      ["bold", "italic", "underline"],
      [{ 'color': ["#000000", "#0f286c", "#ed822a"]}],
      ['spanblock']
    ],
    isVisible: false,
    }
  },
  mounted() {
    var icons = Quill.import("ui/icons");
    icons["spanblock"] = 'p';
    this.content = this.text;
  },
  methods: {
    myHandleImage() {
      var range = this.editorFocused.getSelection(true)
      console.log("range = ", range)
      this.editorFocused.insertText(range.index, ' ', { 'spanblock': true });
      this.editorFocused.setSelection(range.index + 1)
      // this.editorFocused.formatText(range,'spanblock', true);
      // var data = '<p style="margin-top: 16px;"><p style="margin-top: 16px;"><span style="color: red; margin-top: 16px;"><br>Hello World!</span></p></p>'
      // this.editorFocused.clipboard.dangerouslyPasteHTML(range.index, data);
    },
    onUpdate(content) {
      if (this.content != this.text)
        this.$emit('onEdit', content.html)
    },
    onUnFocus() {
      this.isVisible = false
    },
    onFocus(quill) {
      this.editorFocused = quill; 
      this.isVisible = true
    },
  }
};
</script>

<style scoped>

/deep/ .quill-editor {
  position: relative;
}

/deep/ .ql-editor > p{
 margin-bottom: 0;
}

/deep/ .content > p{
 margin-bottom: 0;
}

/* /deep/ .ql-editor > p:only-child{
 margin-bottom: 0;
}

/deep/ .content > p:only-child{
 margin-bottom: 0;
} */

/deep/ .ql-toolbar {
  position: absolute;
  top: -30px;
  min-width: 115px;
}

/deep/ .ql-spanblock {
      padding: 0 !important;
}

/deep/ .invisibleToolbar .ql-toolbar {
  visibility: hidden;
}

/deep/ img {
  max-width: 100%;
}

/deep/ .spanblock {
  margin-top: 16px;
}

p {
  margin: 0 !important;
}

</style>