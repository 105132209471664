<template>
  <v-container>
    <v-row class="mt-5">
        <v-col v-for="(item) in datas" :key="item.link" class="d-flex justify-center pa-10" md="3" cols="6">
        <v-hover v-slot="{ hover }">
            <v-card  :to="'/actualite/' + item.link" :elevation="hover ? 12 : 0">
                <v-img :src="item.img"
                ></v-img>
            <v-card-title class="pa-2 mt-2 mb-1" style="line-height: normal">
                {{item.title}}
            </v-card-title>
            <v-card-text class="pa-2" style="line-height: normal">
                {{item.summary}}
            </v-card-text>
            </v-card>
        </v-hover>
        </v-col>
    </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'ActualitesComp',
    props: {
        type: String
    },
    components: {
    },
    computed: {
    },
    data: () => (
        {
            datas: [],
            data_ready: false,
        }
    ),
    mounted() {
        this.get_datas()
    },
    methods: {
        get_datas() {
        this.$http.get(process.env.VUE_APP_URL + "/api/actualites/type/"+this.type)
            .then(response => {
                this.datas = response.data
                this.data_ready = true
            })
            .catch(error => console.log(error))
        }

    }
}
</script>

<style scoped>
p {
  line-height: 4 !important;
}

</style>