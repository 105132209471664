<template>
  <v-container fluid class="pa-0" v-if="data_ready">
    <v-container class="mt-15 mb-3">
      <v-card flat>
        <EditableText @onEdit="event => editContent(event, page_data.presentation.id, 'title')" :text="page_data.presentation.title"/>
        <div class="d-flex justify-center mt-1">
          <EditableText @onEdit="event => editContent(event, page_data['presentation'].id, 'content')" :text="page_data.presentation.content" class="mt-5" style="line-height: 1.7rem"/>
          <v-img v-if="$vuetify.breakpoint.mdAndUp" :src="require(`@/assets/${page_data.presentation.img}`)" :lazy-src="require(`@/assets/lazy/${page_data.presentation.img}`)" class="ml-15 pl-15" width="36%" />
        </div>
      </v-card>
      <br>
    </v-container>
    <v-card flat class="pa-0 pb-10" color="grey lighten-4">
      <v-container fluid>
        <br><br>
        <v-container class="mb-5">
          <h2 class="text-center" style="font-weight: 700">LES ASSOCIÉS</h2>
          <div class="d-flex justify-center">
            <div style="width: 100px; height: 50px;">
              <hr>
            </div>
          </div>
        </v-container>
        <v-container fluid>
              <div href="team" class="d-flex flex-wrap justify-center">
                <v-card v-for="(item, index) in page_data['member']" class="ml-7 mr-7 d-flex"  :key="index"
                v-bind:class="[index % 2 == 1 && windowWidth > 1750 ? 'align-end' : 'align-start']" flat color="transparent" width="280" :height="getCardHeight">
                  <v-card>
                <template slot="progress">
                  <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                  ></v-progress-linear>
                </template>
                <v-img :src="require(`@/assets/${item.img}`)" :lazy-src="require(`@/assets/lazy/${item.img}`)" contain alt="" width="280"/>
                <v-card-title class="mt-2 pa-1 d-flex justify-center">
                  <v-btn v-if="item.additional_text" icon :href="item.additional_text" target="_blank">
                    <v-icon>mdi-linkedin</v-icon>
                  </v-btn>
                  <EditableText @onEdit="event => editContent(event, item.id, 'title')" :text="item.title"/>
                </v-card-title>
                <v-divider class="mx-4"></v-divider>
                <v-card-title class="pa-1 d-flex justify-center text-center" style="font-size: 1rem">
                  <EditableText @onEdit="event => editContent(event, item.id, 'content')" :text="item.content"/>
                </v-card-title>
                  </v-card>
                </v-card>
              </div>
        </v-container>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src

import Edit from "@/components/Edit";

import EditableText from "@/components/EditableText";

export default {
  extends: Edit,
  name: 'Cabinet',
  components:{
    EditableText
  },
  computed: {
    getCardHeight() {
      if (this.windowWidth > 1750)
        return 600
      return 400
    }
  },
  data: () => (
      {
        page_name: "cabinet",
      }
  ),
}
</script>
