<template>
  <v-container v-if="data_ready" style="max-width: 1000px">
    <h1 class="text-center mt-10">VOUS ÊTES ?</h1>
    <div class="d-flex justify-center">
      <div style="width: 100px; height: 50px;">
        <hr>
      </div>
    </div>
    <v-row class="mt-5 mb-15" >
      <v-col v-for="(card, index) in status" :key="index" class="d-flex justify-center">
        <v-hover v-slot="{ hover }">
          <v-card :to="'/vousEtesDesc/'+card.id" class="text-center" width="260" :elevation="hover ? 12 : 0">
            <div class="d-flex justify-center">
              <v-avatar size="150">
                <v-img :src="require(`@/assets/${card.img}`)" :lazy-src="require(`@/assets/lazy/${card.img}`)" alt="John"
                ></v-img>
              </v-avatar>
            </div>
            <v-card-title class="justify-center" style="font-size: 1.45rem"  v-html="card.title">
            </v-card-title>
            <v-card-text>
            <EditableText @onEdit="event => editContent(event, card.id, 'presentation')" :text="card.presentation"/>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>

import Edit from "@/components/Edit";

import EditableText from "@/components/EditableText";

export default {
  extends: Edit,
  name: 'VousEtes',
  components:{
    EditableText
  },
  data: () => (
      {
        status: null,
        tab: null,
        data_ready: false,
        page_name: "vousetes",
      }
  ),
  mounted() {
    this.get_status_data()
  },
  methods: {
    get_status_data() {
      this.$http.get(process.env.VUE_APP_URL + "/api/pageitems/vousetes")
          .then(response => {
            console.log(response.data)
            this.status = response.data['status']
            this.data_ready = true
          })
          .catch(error => console.log(error))
    }
  }
}
</script>

<style>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}


</style>