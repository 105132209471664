<template>
  <v-app id="app">
    <NavBar/>
    <v-main class="main-page">
      <router-view/>
      <!-- @dataready="blockData" v-bind:class="{ 'd-none': notLoaded }" -->
      <!-- <Loader v-if="notLoaded"/> -->
    </v-main>
    <Footer v-if="!isContact"/>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar';
import Footer from './components/Footer';
// import Loader from './components/Loader';

export default {
  name: 'App',

  components: {
    NavBar,
    Footer,
    // Loader
  },
  data: () => ({
    notLoaded : true
  }),
  computed: {
    isContact() {
      return this.$route.name === 'Contact'
    }
  },
  
  methods :{
    blockData(){
      console.log('test')
        this.notLoaded = false
    }
  },
  
};
</script>


<style>

@import url('https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:100,200,300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Cabin:100,200,300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Hachi+Maru+Pop:100,200,300,400,500,600,700,800');

#app {

}


/* @font-face {
  font-family: "Mathilde";
  src: url("./fonts/Mathilde/mathilde.otf");
  }

@font-face {
    font-family: "Mathilde";
    src: url("./fonts/Mathilde/mathilde-bold.otf");
    font-weight: bold;
} */


  .main-page{
    min-height: 100vh;
  }

  h1, h2 {
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
  }

  h2 {
    font-size: 1.75rem;
  }

  

  #SiteSlogan {
    align-items: end !important;
    text-shadow: 1px 1px 3px #000;
  }
  /*#SiteSlogan h1 {*/
  /*  font-size: 50px;*/
  /*  font-weight: 700 !important;*/
  /*}*/
</style>