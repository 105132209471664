<template>
  <v-container fluid class="pa-0">
    <section style="margin-top: -64px">
      <v-img src="@/assets/cabinet-exter.jpg" lazy-src="@/assets/lazy/cabinet-exter.jpg" style="height: 100vh" :min-height="getHomeHeight">
        <div class="fill-height bottom-gradient">
        <v-overlay absolute opacity="0.45">
            <v-card class="d-flex align-content-space-between flex-wrap" color="transparent" flat style="padding-top: 75px" :min-height="getHomeHeight" tile height="100vh">
              <v-card  v-if="$vuetify.breakpoint.mdAndUp" flat color="transparent" style="width: 100%;" height="20%">
              </v-card>
              <v-card class="d-flex justify-center pa-2" outlined tile width="100%" color="transparent" height="50%">
                  <div style="max-width: 500px; min-width: 100px"
                  class="d-flex align-content-center flex-wrap pa-5 text-center" v-bind:class="{ bb: $vuetify.breakpoint.smAndUp}"
                >
                  <v-img  class="mb-3" height="170" src="@/assets/logo-white.png" lazy-src="@/assets/lazy/logo-white.png" contain></v-img>
                  <span style="width: 100%; letter-spacing: 1.2px; font-size: 0.9rem; font-weight: 500;">CABINET D'EXPERTISE COMPTABLE</span>
                
                </div>
              </v-card>
              <v-card class="d-flex  pa-2" :class="$vuetify.breakpoint.mdAndUp ? 'justify-space-between': 'align-content-end flex-wrap'" outlined tile width="100%" color="transparent" :height="$vuetify.breakpoint.smAndUp ? '25%': '25%'" >
                <v-card flat color="transparent" class="d-flex align-end justify-center" :width="getIconWidth">
                  <div class="pa-3 text-center">
                    <span>13/15 rue de l'Ambroisie,<br> 75012, Paris<br>01 53 46 66 46</span>
                     <v-divider class="ma-2" dark width="200"></v-divider>
                    <span>11 rue du Général Leclerc,<br> 94520, Mandres les Roses <br>01 45 98 92 00</span>
                  </div>
                </v-card>
                <v-card flat color="transparent" class="d-flex align-end justify-center" :width="getIconWidth">
                    <div class="ma-5">
                      <a href="https://fr-fr.facebook.com/sillageexperts/">
                        <v-icon x-large>mdi-facebook</v-icon>
                      </a>
                    </div>
                    <div class="ma-5">
                      <a href="https://www.linkedin.com/company/sillagexperts/">
                        <v-icon x-large>mdi-linkedin</v-icon>
                      </a>
                    </div>
                </v-card>
                <v-card flat color="transparent" class="d-flex align-end text-right justify-center" :width="getIconWidth">
                </v-card>
              </v-card>
            </v-card>
        </v-overlay>
        </div>
      </v-img>
    </section>
    <v-container class="mt-15 mb-3"  id="cabinet" style="min-height:  713px">
      <v-card flat v-if="page_data">
        <EditableText @onEdit="event => editContent(event, page_data.presentation.id, 'title')" :text="page_data.presentation.title" />
        <div class="d-flex justify-center mt-1">
          <EditableText @onEdit="event => editContent(event, page_data['presentation'].id, 'content')" :text="page_data.presentation.content" class="mt-5" style="line-height: 1.7rem"/>
          <v-img v-if="$vuetify.breakpoint.mdAndUp" :src="require(`@/assets/${page_data.presentation.img}`)" :lazy-src="require(`@/assets/lazy/${page_data.presentation.img}`)" class="ml-15 pl-15" width="36%" />
        </div>
      </v-card>
      <br>
    </v-container>
    <v-card flat class="pa-0 py-10" color="grey lighten-4">
      <v-container fluid>
        <h1 class="text-center" style="font-weight: 700">LES ASSOCIÉS</h1>
        <div class="d-flex justify-center mb-5">
          <div style="width: 100px; height: 50px;">
            <hr>
          </div>
        </div>
        <v-container fluid class="" v-resize="updateLine">
              <div class="d-flex flex-wrap justify-center team" v-if="page_data" >
                 <TeamCard class="ml-5 mr-5" @editCard="onCardChange" :member="page_data['member'][0]" />
                  <TeamCard class="ml-5 mr-5" @editCard="onCardChange" :member="page_data['member'][1]" :offset="same_line"/>
                  <TeamCard class="ml-5 mr-5" @editCard="onCardChange" :member="page_data['member'][2]"/>
                <div class="d-flex flex-wrap justify-center" ref="down">
                  <TeamCard class="ml-5 mr-5" @editCard="onCardChange" :member="page_data['member'][3]" :offset="same_line">
                  </TeamCard>
                  <TeamCard class="ml-5 mr-5" @editCard="onCardChange" :member="page_data['member'][4]">
                  </TeamCard>
                </div>
              </div>
        </v-container>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TeamCard from '../components/TeamCard.vue'
// @ is an alias to /src
import Edit from "@/components/Edit";
import EditableText from "@/components/EditableText";
export default {
  extends: Edit,
  name: 'Home',
  components:{
    EditableText,
    TeamCard
  },
  computed: {
    getHomeHeight() {
      if (this.$vuetify.breakpoint.smAndUp)
        return 700
      return 500
    },
    getIconWidth() {
      if (this.$vuetify.breakpoint.mdAndUp)
        return '300px'
      return '100%'
    },
  },
  data: () => (
      {
        same_line: {'value': 0},
        page_name: "home",
        page_width: 5000,
        social: [
          {name:'facebook',icon:'mdi-facebook'},
          {name: 'twitter',icon:'mdi-twitter'},
          {name:'linkedin',icon:'mdi-linkedin'}
        ],
      }
  ),
  mounted() {
    this.updateLine()
  },
  methods: {
    
    updateLine() {
        if(this.$refs.down)
          this.same_line['value'] = this.$refs.down.offsetTop
    },
    onCardChange(e){
      console.log("e = ", e)
      this.editContent(e.event, e.id, e.key)
    },
    getImgUrl(pic) {
      return require('../assets/'+pic)
    },
  }
}
</script>


<style scoped>
  /deep/ .v-banner__wrapper {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .v-application a{
    color: transparent;
  }
  .rectangle {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: #424242;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 800;
    border-radius: 4px;
    }
  .v-overlay > :nth-child(2) {
    width: 100% !important;
    min-height: 100% !important;
  }

  /deep/ .team p {
    margin-bottom: 0 !important;
  }
</style>

<style lang="scss" scoped>
$anime-time: 8s;
$box-width: 500px;
$box-height: 300px;
$clip-distance: .05;
$clip-width: $box-width * (1 + $clip-distance * 2);
$clip-height: $box-height * (1 + $clip-distance * 2);
$path-width: 2px;
$main-color: #ffffff;
$codepen-logo-path: url('https://www.sillage-experts.fr/assets/images/design/logo-white.png');
%full-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.bb {
  @extend %full-fill;
  width: $box-width;
  height: $box-height;
  margin: auto;
  // background: rgba(15, 40, 108, 0.5);
  // background: $codepen-logo-path no-repeat 50% / 70% rgba(#000, .1);
  color: $main-color;
  font-family: 'Roboto', sans-serif; 
  // font-family: 'Mathilde';
  // font-size: 5rem;
  // font-weight: 400;
  // line-height: 0.4;
  
  box-shadow: inset 0 0 0 1px rgba($main-color, .5);
  &::before,
  &::after {
    @extend %full-fill;
    content: '';
    z-index: -1;
    margin: -1 * $clip-distance * 100%;
    box-shadow: inset 0 0 0 $path-width; 
    animation: clipMe $anime-time linear infinite;
  }
  &::before {
    animation-delay: $anime-time * -.5;
  }
  // for debug
}
@keyframes clipMe {
  0%, 100% {clip: rect(0px, $clip-width, $path-width, 0px); }
  25% {clip: rect(0px, $path-width, $clip-height, 0px); }
  50% {clip: rect($clip-height - $path-width, $clip-width, $clip-width, 0px); }
  75% {clip: rect(0px, $clip-width, $clip-height, $clip-width - $path-width); }
}
/////
html,
body {
  height: 100%;
}
body {
  position: relative;
  background-color: #0f222b;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
</style>