<template>
  <v-container fluid class="back" style="min-height: 100vh">
    <h1 class="text-center mt-10" style="font-size: 2.4rem">Mentions Légales</h1>
		<h2>1. INFORMATIONS ÉDITEUR</h2>
        <p>Ce site Internet est la propriété de SILLAGE Experts.</p>
        <p>Siège social : 13/15 rue de l’Ambroisie - 75012 Paris<br>Numéro d'immatriculation au RCS de Paris : 480 070 523
        <br/>Numéro d'identification à la TVA : FR 07 480070523<br>Tél. 01 53 46 66 46</p>
        <p>Le Directeur de la publication du site Internet est Mme Elisabeth THIBOUT
        <br>Les mises à jour sont assurées par SILLAGE Experts
        <br>Pour toute suggestion, information, réaction concernant ce site, n’hésitez pas à écrire au webmestre via son adresse mail (voir bas de page)</p>
        <h2>2. INFORMATIONS GÉNÉRALES</h2>
        <p>Traitement des données personnelles : Votre adresse électronique, qui a fait l’objet d’une inscription volontaire,  sert uniquement à acheminer notre réponse à vos questions. Elle ne sera en aucun cas communiquée aux tiers. En application de l’article 27 de la loi Informatique et libertés en date du 6 février 1978, vous disposez d’un droit de modification et de suppression des données qui vous concernent. Pour cela, vous êtes libre, à tout moment, de nous le signaler à l’adresse : contact@sillage-experts.fr</p>
        <h2>3. TECHNIQUES D’INFORMATION</h2>
        <h4>CONCEPTION DU SITE</h4>
        <span>PANDA DEV</span>
        <h4>DEVELOPPEMENT / PROGRAMMATION :</h4>
        <span>PANDA DEV</span>
        <h4>HEBERGEMENT DU SITE :</h4>
        <span>OVH - RCS Roubaix – 2, rue Kellermann – 59100 Roubaix – France</span>
        <h2>CONDITIONS DE SERVICE</h2>
        <p>Ce site est proposé en langage Javascript (NodeJs, VueJs), pour un meilleur confort d’utilisation et un graphisme plus agréable, nous vous recommandons de recourir à des navigateurs modernes comme Safari, Firefox, Chrome, ...</p>
        <h2>INFORMATIONS ET EXCLUSIONS</h2>
        <p>Sillage Experts a mis en œuvre tous les moyens dont il dispose pour assurer une information fiable et une mise à jour fiable de ses sites internet. Toutefois, des erreurs ou omissions peuvent survenir. L’internaute devra donc s’assurer de l’exactitude des informations auprès de Sillage Experts, et signaler toutes les modifications du site qu’il jugerait utiles. Sillage Experts n’est en aucun cas responsable de l’utilisation faite de ces informations, et de tout préjudice direct ou indirect pouvant en découler.</p>
        <h4>LIENS HYPERTEXTES</h4>
        <p>Le site internet de Sillage Experts peut offrir des privilèges vers d’autres sites internet ou d’autres ressources disponibles sur Internet.</p>
        <p>Sillage Experts ne dispose d’aucun moyen pour contrôler les sites en connexion avec ses sites internet. Sillage Experts ne répond pas de la disponibilité de tels sites et sources externes, ni ne la garantie. Le cabinet ne peut être tenue pour responsable de tous dommages, de quelque nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services qu’ils proposent, ou de tout usage qui peut être fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à l’internaute, qui doit se conformer à leurs conditions d’utilisation.</p>
        <p>Les utilisateurs, les abonnés et les visiteurs des sites internet de Sillage Experts ne peuvent mettre en place un hyperlien en direction de ce site sans l’autorisation expresse et préalable de Sillage Experts.</p>
        <p>Dans l’hypothèse où un utilisateur ou visiteur souhaiterait mettre en place un hyperlien en direction d’un des sites internet de Sillage Experts, il lui appartiendra d’adresser un email à contact@sillage-experts.fr afin de formuler sa demande. Sillage Experts se réserve le droit d’accepter ou de refuser un hyperlien sans avoir à justifier sa décision.</p>
        <h4>RECHERCHE</h4>
        <p>En outre, le renvoi sur un site internet pour compléter une information recherchée ne signifie en aucune façon que Sillage Experts reconnaît ou accepte quelque responsabilité quant à la teneur ou à l’utilisation dudit site.</p>
        <h4>PRÉCAUTIONS D’UTILISATION</h4>
        <p>Il vous incombe par conséquent de prendre les précautions d’usage nécessaires pour vous assurer que ce que vous choisissez d’utiliser ne soit pas entaché d’erreurs voire d’éléments de nature sauvage.</p>
        <h4>RESPONSABILITÉ</h4>
        <p>Aucune autre garantie n’est accordée au client, auquel incombe l’obligation de formuler clairement ses besoins et le devoir de s’informer. Si des informations fournies par Sillage Experts apparaissent inexactes, il appartiendra au client de procéder lui-même à toutes vérifications de la cohérence ou de la vraisemblance des résultats obtenus. Sillage Experts ne sera en aucune façon responsable de l'utilisation par le client des informations  contenues dans  ses sites Internet, ni de l'absence de celles-ci, .</p>
        <h4>CONTACTEZ-NOUS</h4>
        <p>Sillage Experts est à votre disposition pour tous vos commentaires ou suggestions. Vous pouvez nous écrire en français par courrier électronique à contact@sillage-experts.fr</p>
  </v-container>
</template>

<script>

export default {
  name: 'Mentions',
  components: {
  },
  computed: {
  },
}
</script>

<style scoped>



</style>