<template>
  <v-container>
      <h1 class="text-center mt-10">ACTUALITES</h1>
      <div class="d-flex justify-center mb-5">
        <div style="width: 100px; height: 1px;">
          <hr>
        </div>
      </div>
      <br/>
      <div >
        <v-tabs  class="" v-model="tab" show-arrows hide-slider>
          <div class="d-flex justify-center" style="width: 100%;">
            <v-tab v-for="(item, index) in types" :key="index" class="d-flex title mr-5" >
              {{item.type}}
            </v-tab>
          </div>
          <v-tab-item v-for="(item, index) in types" :key="index">
              <ActualitesComp :type="item.type"/>
          </v-tab-item>
        </v-tabs>
      </div>
  </v-container>
</template>

<script>
import ActualitesComp from '../components/ActualitesComp.vue'
export default {
  name: 'Actualités',
  components: {
    ActualitesComp
    // HelloWorld
  },
  computed: {
  },
  data: () => (
      {
        tab: null,
        types: [],
        data_ready: false,
      }
  ),
  mounted() {
    this.get_type()
  },
  methods: {
    get_type() {
      this.$http.get(process.env.VUE_APP_URL+ "/api/actualites/type")
          .then(response => {
            console.log(response.data)
            console.log(typeof response.data[0].img);
            this.types = response.data
            this.data_ready = true
          })
          .catch(error => console.log(error))
    }

  }

}
</script>


<style scoped>
  .v-tab--active{
    color: orange
  }

</style>