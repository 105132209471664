<template>
    <v-card class="d-flex mb-10"
        v-bind:class="[offset.value < 300 ? 'align-end' : 'align-start']" flat color="transparent" width="215" :height="getCardHeight">
        <v-card height="340">
            <template slot="progress">
                <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                ></v-progress-linear>
            </template>
            <v-img :src="require(`@/assets/${member.img}`)" :lazy-src="require(`@/assets/lazy/${member.img}`)" contain alt="" width="215"/>
            <v-card-title class="mt-2 pa-1 d-flex justify-center" style="font-size: 1rem">
                <v-btn v-if="member.additional_text" icon :href="member.additional_text" target="_blank">
                    <v-icon>mdi-linkedin</v-icon>
                </v-btn>
                <EditableText @onEdit="event => editCard(event, member.id, 'title')" :text="member.title"/>
            </v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text class="pa-1 d-flex justify-center text-center">
                <EditableText @onEdit="event => editCard(event, member.id, 'content')" :text="member.content"/>
            </v-card-text>
        </v-card>          
    </v-card>
</template>

<script>
import EditableText from "@/components/EditableText";
export default {
    name: 'TeamCard',
    props: {
        member: Object,
        offset: 
        {
            type: Object, 
            default: function () {
                return { value: 600 }
      }
        },
    },
    components: {
        EditableText
    },
    computed: {
        getCardHeight() {
        if (this.offset['value'] < 300)
        {
            console.log("offset = ", this.offset['value'])
            return 400
        }
        return 'auto'
        },
    },

    data: () => (
        {
            same_line: false,
        }
    ),
    methods: {
        editCard(event, id, key) {
            console.log("editing card = ", {'event': event, 'id': id, 'key': key})
            this.$emit('editCard', {'event': event, 'id': id, 'key': key})
        },
    }
}
</script>

<style scoped>
p {
  line-height: 4 !important;
}

/deep/ .team::first-line toto {
    align-items: flex-end;
    height:450px;
}

</style>